@use 'abstracts/variables';
@use 'abstracts/colors';
@use 'abstracts/bootstrap';

.mat-button {
  border-radius: 2px !important; // stylelint-disable-line declaration-no-important

  .mat-button-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.primary-button {
  background-color: map-get(colors.$color, 'primary');
  box-shadow: 0 8px 16px rgba(map-get(colors.$color, 'primary'), 0.4);
  color: map-get(colors.$color, 'background-04');
  font-size: map-get(variables.$font-size, 'small');
  font-weight: map-get(variables.$font-size, 'medium');
  padding: 2px 24px !important; // stylelint-disable-line declaration-no-important

  @include bootstrap.media-breakpoint-up(md) {
    padding: 2px 40px !important; // stylelint-disable-line declaration-no-important
  }

  &:hover {
    background-color: map-get(colors.$color, 'primary-dark');
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    background-color: map-get(colors.$color, 'divider');
    box-shadow: none;
  }
}

.secondary-button {
  border: 1px solid map-get(colors.$color, 'secondary') !important; // stylelint-disable-line declaration-no-important
  color: map-get(colors.$color, 'secondary');
  font-size: map-get(variables.$font-size, 'small');
  font-weight: map-get(variables.$font-size, 'medium');
  padding: 1px 23px !important; // stylelint-disable-line declaration-no-important

  @include bootstrap.media-breakpoint-up(md) {
    padding: 1px 39px !important; // stylelint-disable-line declaration-no-important
  }

  &:hover {
    border: 1px solid map-get(colors.$color, 'background-05') !important; // stylelint-disable-line declaration-no-important
    color: map-get(colors.$color, 'background-05');
  }

  &:disabled,
  &.disabled {
    border: 1px solid map-get(colors.$color, 'divider') !important; // stylelint-disable-line declaration-no-important
    color: map-get(colors.$color, 'divider');
  }
}

.tertiary-button {
  background-color: map-get(colors.$color, 'background-05');
  color: map-get(colors.$color, 'background-04');
  font-size: map-get(variables.$font-size, 'tiny');
  font-weight: map-get(variables.$font-size, 'medium');
  line-height: 17px !important; // stylelint-disable-line declaration-no-important
  padding: 4px 10px !important; // stylelint-disable-line declaration-no-important
}

:not(.mat-calendar-controls) > .mat-icon-button {
  display: inline-flex !important; // stylelint-disable-line declaration-no-important
  font-size: 1.5rem;
  height: auto !important; // stylelint-disable-line declaration-no-important
  line-height: 1em !important; // stylelint-disable-line declaration-no-important
  padding: 0.25rem !important; // stylelint-disable-line declaration-no-important
  width: auto !important; // stylelint-disable-line declaration-no-important

  .mat-button-wrapper {
    display: inline-flex !important; // stylelint-disable-line declaration-no-important
  }
}
