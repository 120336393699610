$grid-gutter-width: 24px;
$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 960px,
  lg: 1280px,
  xl: 1920px,
);
$container-max-widths: (
  sm: 960px - $grid-gutter-width,
  md: 1280px - $grid-gutter-width,
  lg: 1440px - $grid-gutter-width,
  xl: 1440px,
);

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
